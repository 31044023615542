import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bands from "../components/bands"
import Filmmakers from "../components/filmmakers"
import Hosts from "../components/hosts"
import Tickets from "../components/tickets"

const BLACK = "black"

class EventPage extends React.Component {
  render() {
    return (
      <Layout
        color={BLACK}
        background="#89C190"
        classNames="page page--event page--text-black"
      >
        <SEO
          title="Event"
          pathname={this.props.location.pathname}
          description="EuroNoize is a live music event modelled on the Eurovision song contest, but featuring a bizarre cast of DiY, alternative and underground subpop bands."
        />
        <div className="container p-4">
          <h2 className="text-center">Event</h2>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 md:text-xs md:pr-8">
              <p>
                EuroNoize was a live music event modelled on the Eurovision song
                contest, but featuring a bizarre cast of DiY, alternative and
                underground subpop bands. It took place on May 23rd 2019 at the
                Scala. It featured musicians from across Europe who performed
                one new song in front of a live audience. The event was streamed
                live online so the audience could cast their vote from afar for
                their favourite performance of the evening.{" "}
              </p>
              <p>
                EuroNoize is a celebration of the obscure, the niche and the
                uncategorizable, music that runs deeper than national borders.
                It represents the secret international fellowship of punx and
                weirdos stretching from Russia to Ireland.{" "}
              </p>
            </div>

            <div className="w-full md:w-1/2 font-sans md:pl-4 md:pl-8 uppercase leading-tight md:text-sm sm:text-base">
              <h2 className="text-center">&nbsp;</h2>
              <p>
                {/* May 23, 19:00<br />
                <br />
                SCALA LONDON<br />
                275 Pentonville Rd<br />
                London N1 9NL<br />
                <br />
                <Tickets /> */}
                The event was held on the 23rd of May, 2019 at Scala, London.
              </p>
              <p>
                <Tickets />
              </p>
            </div>
          </div>
          <Bands color={BLACK} />
          <Filmmakers color={BLACK} />
          <Hosts color={BLACK} />
        </div>
      </Layout>
    )
  }
}

export default EventPage
